import * as React from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import firebaseKeys from 'config/keys.prod';
import { Firestore, getFirestore, initializeFirestore } from 'firebase/firestore/lite';

type FirebaseContextType = {
  firebaseApp: FirebaseApp;
  db: Firestore;
};

const FirebaseContext = createContext<FirebaseContextType>(null!);

export function FirebaseProvider({ children }: { children: React.ReactNode }) {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp>(null!); // all other components get access to firebaseApp through FirebaseContext
  const [db, setDb] = useState<Firestore>(null!);

  useEffect(() => {
    // const app = initializeApp(Constants!.manifest!.extra as FirebaseOptions);
    const app = initializeApp(firebaseKeys.firebaseConfig);
    initializeFirestore(app, { ignoreUndefinedProperties: true });
    setFirebaseApp(app);
    setDb(getFirestore(app));
  }, []);

  const context = useMemo<FirebaseContextType>(
    () => ({
      firebaseApp,
      db,
    }),
    [firebaseApp]
  );

  return !!firebaseApp && <FirebaseContext.Provider value={context}>{children}</FirebaseContext.Provider>;
}

export function useFirebase() {
  return useContext(FirebaseContext);
}
