/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  firebaseConfig: {
    apiKey: 'AIzaSyDcA3IwUCxJBRNab6Ob5Kf9jt1LiGHoOC0',
    authDomain: 'gigup-prod.firebaseapp.com',
    projectId: 'gigup-prod',
    storageBucket: 'gigup-prod.appspot.com',
    messagingSenderId: '192361227402',
    appId: '1:192361227402:web:1052c46e531ba25d20f973',
    measurementId: 'G-8CW6B6JW2N',
  },
};
