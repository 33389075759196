import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useAuth } from '../services/auth.service';
import Backdrop from 'modules/shared/images/Backdrop.png';
import classes from './auth.module.css';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      Copyright ©&nbsp;
      <Link color="inherit" href="https://gigup.me/">
        GigUp.me
      </Link>
      &nbsp;{new Date().getFullYear()}.
    </Typography>
  );
}

export function LoginScreen() {
  const { loginWithEmail } = useAuth();
  const navigate = useNavigate();
  const img = require('modules/shared/images/Backdrop.png');
  const logo = require('modules/shared/images/giguplogo.png');
  const backdrop = 'url(' + img + ')';
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
    await loginWithEmail(data.get('email')!.toString(), data.get('password')!.toString());

    // todo: make sure that login is successfull
    navigate('/');
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: backdrop,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img alt="logo" src={logo} className={classes.logo} />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Grid container sx={{ margin: '3px 0 0;' }} justifyContent={'right'}>
              <Link component={RouteLink} to="/forgotPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>

            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Grid container direction={'column'} sx={{ margin: '15px 0 0;' }} justifyContent={'right'}>
              <Typography sx={{ textAlign: 'center' }} component="div" variant="body2">
                Not yet a GigUp user?
              </Typography>
              <Button
                type="button"
                fullWidth
                onClick={() => (window.location.href = 'https://www.gigup.me/contact-us/')}
                variant="outlined"
                sx={{ mt: 1, mb: 2 }}
              >
                Contact Us
              </Button>
            </Grid>

            <Grid container>
              <Grid item>
                {/* <Link component={RouteLink} to='/register' variant='body2'>
                  Don't have an account? Sign Up
                </Link> */}
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
